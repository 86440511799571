@import "../../global.scss";

.testimonials{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @include mobile{
        justify-content: space-around;
    }
    h1{
        font-size: 50px;

        @include mobile{
            font-size: 20px;
        }
    }
    .container{
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
       
        @include mobile{
            flex-direction: row;
            height: 100%;
        }

        .card{
            width: 320px;
            height: 65%;
            margin: 0 20px;
            border-radius: 10px;
            box-shadow: 0 0 15px -8px black;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            transition:all 1s ease;



            @include mobile{
                height: 130px;
                width: 280px;
                // margin: 10px 0;
            }

            &.featured{
                width: 330px;
                height: 68%;
                margin: 0 30px;
                @include mobile{
                    height: 130px;
                    width: 280px;
                    margin: 1px;
                }
            }
            &:hover{
                transform:scale(1.1)
            }

            .top{
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    &.left,
                    &.right{
                        height: 25px;
                    }
                    &.user{
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin: 0 30px;

                        @include mobile{
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
            .center{
                padding: 10px;
                border-radius: 10px;
                background-color: rgb(255, 169, 169);
                

                @include mobile{
                    font-size: 12px;
                    padding: 5px;
                }
            }
            .bottom{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                h3{
                    margin-bottom: 5px;
                    @include mobile{
                        font-size: 14px;
                        
                    }
                }
                h4{
                    color: crimson;
                    @include mobile{
                        font-size: 12px;
                        
                    }
                }
            }

        }
    }
}