@import "../../global.scss";

.portfolio{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
h1{
    font-size: 40px;
    height: 8%;
    @include mobile{
    font-size: 20px;
    }
}

ul{
    height: 5%;
    margin:10px;
    padding:0;
    list-style: none;
    display: flex;
    @include mobile{
        margin: 10px 0;
        flex-wrap: wrap;
        justify-content: center;
        
    }
}

.container{
    overflow: hidden;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mobile{
        width: 100%;

    }

    .item{
        width: 250px;
        height: 350px;
        border-radius: 20px;
        border: 1px solid lightgray;
        margin: 10px;
        display: flex;
        align-items:center;
        justify-content: center;
        color: white;
        position: relative;
        transition: all 1 ease;
        cursor: pointer;

        @include mobile{
            width: 150px;
            height: 120px;
            margin-top:5px;
            padding:1px 5px;
            
        }

        h3, p{
            position: absolute;
            font-size: 20px;
            text-align: center;
            margin: 10px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
        &:hover{
            background-color: $mainColor;
            img{
                opacity: 0.5;
                z-index: 0;

            }
        }
    }
    .desc{
        padding: 10px;
        border-radius: 10px;
        margin-top: 20px;
        width: 230px;
        margin:10px;
        height: 160px;
        line-height: 1.4;
        margin: 0 5px;
        background-color: white;
        color:black;
        border: solid 2px black;


        .link{
            color: black;
            font-size: 30px;
            margin:5px;
        }
        &:hover{
            color:black;
            background-color: white;
            text-decoration: none;
        }


        @include mobile{
            width: 150px;
            height: auto;
            margin-top:5px;
            padding:1px 5px;
            
        }
    }
    
}