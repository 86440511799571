@import "../../global.scss";

.intro{
    background-color: white;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;

    }

    .left{
        flex:0.5;
        overflow: hidden;
        .imgContainer{
            width: 700px;
            height: 700px;
            background-color: crimson;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            float: right;
            @include mobile{
                align-items: flex-start;
            }

            img{
                height: 100%;
                @include mobile{
                    height: 50%;
                }
            }
        }
    }
    .right{
        flex: 0.5;
        position: relative;
        .wrapper{
            width:100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0;
                align-items: center;
            }
            .brand{
                padding: 10px;
                border-radius: 10px;
                background-color: rgb(255, 169, 169);
                margin-top: 20px;
                width: 70%;
                height: 12%;
                line-height: 1.4;
            }
            h1{
                font-size:60px;
                margin:10px 0;
                @include mobile{
                    font-size: 26px;
                }
            }
            h2{
                font-size: 35px;
                @include mobile{
                    font-size: 20px;
                }
            }
            h3{
                font-size: 30px;

                @include mobile{
                    font-size: 15px;
                }

                span{
                    font-size: inherit;
                    color: crimson;
                }
                .ityped-cursor{
                    animation:blink 1s infinite;
                }
                
                @keyframes blink {
                    50%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 0;
                    }
                }
            }
        }
        a{
            position:absolute;
            bottom: 10px;
            left: 40%;
            img{
                width: 35px;
                animation: arrowBlink 2s infinite;
            }
            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }
            
        }
    }
}
.MuiSvgIcon-root {
    font-size: large;
}