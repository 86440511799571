@import "../../global.scss";

.menu{
    width: 300px;
    height: 100vh;
    background-color: $mainColor;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    transition: all 1s ease;
    display: flex;
    justify-content: center;
    

    &.active{
        right: 0;
        
    }
    
    ul{

        height: 100%;
        display: flex;
        align-items:center;
        justify-content: center;
        flex-direction: column;
        margin:0;
        padding: 0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;
        color: white;
        width: 70%;
        
        li{ 
            margin-bottom: 25px;
            a{
                font-size: inherit;
                color: inherit;
                text-decoration: none;
            }
            &:hover{
                font-weight: 500;
            }
        }
    }
}