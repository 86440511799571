@import "../../global.scss";

.works{
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .title{
        position: absolute;
        top:20px;
        color: white;
        
       
    }
    h4{
        position: absolute;
        top:70px;
        color: white;
    }

    .arrow{
        height: 50px;
        position: absolute;
        cursor: pointer; 
        

        @include mobile{
            display: none;
        }

        &.left{
            left: 100px;
            transform: rotateY(180deg);
        }
        &.right{
            right: 100px;
        }
    }

    .slider{
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        @include mobile{
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .item{
                width: 1000px;
                height: 500px;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                
                @include mobile{
                    width: 75%;
                    height: 150px;
                    margin:8px 0; 
                    overflow: hidden;
                }

                .left{
                    
                    flex: 4;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        flex-direction: column;
                        justify-content: space-between;
                        color: black;
                        margin-left: 20px;



                        .desc{
                            padding: 10px;
                            border-radius: 10px;
                            background-color: white;
                            color: black;
                            margin-top: 20px;
                            width: 90%;
                            height: auto;
                            line-height: 1.4;
                        }

                        .imgContainer{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            @include mobile{
                                width: 20px;
                                height: 20px;
                            }

                            img{
                                width: 40px;
                                height: 100%;
                                
                                @include mobile{
                                    width: 15px;
                                }
                            };
                        }
                        h2{
                            margin-top: 20px;
                            font-size: 20px;
                            @include mobile{
                                font-size: 13px;
                            }
                        }
                        a{
                            color: inherit;
                        }
                        p{
                            font-size: 13px;
                            margin-top: 10px;
                            @include mobile{
                            display: none;
                            }
                        }
                        span{
                            margin-top: 20px;
                            font-size: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                   
                }
                .right{
                    flex: 8;
                    height: 500px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img{
                        width: 500px;
                        transform:rotate(0deg);
                        
                    }
                    
                }
            }
        }
    }
}